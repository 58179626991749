.formElement_addRemove,
.addRemoveElement {
  font-size: 9pt;
  cursor: pointer;
  position: absolute;
  top: 0;
  text-align: right;
  background: #eeeeee;
  text-align: center;
}

// article
.addRemoveElement {
  left: 100px;
  width: 100px;
}

.formElement_control .addRemoveElement {
  left: -100px;
  width: 100px;
}

.formElement_addRemove .formElement_addRemove_add,
.addRemoveElement_add {
  width: 40px;
  height: 20px;
  border: solid 1px #999999;
  padding: 3px;
  margin-right: 3px;
  background-color: #ffffff;
  display: inline-block;
  text-align: center;
}

.formElement_addRemove .formElement_addRemove_remove,
.addRemoveElement_remove {
  color: #990000;
  display: inline-block
}