.youtube-responsive-container {
    position:relative;
    padding-bottom:56.25%;
    height:0;
    overflow:hidden;
    margin: 0 0 $lineHeight;
    //border: solid 1px #000000;
    }

.youtube-responsive-container iframe, .youtube-responsive-container object, .youtube-responsive-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border: 0;
    margin-bottom: 24px;
}

.yt {
    margin: $lineHeight 0;
}

.ytLink {
    margin-bottom: 16px;
    font-size: 14pt;
    color: #052378;
    display: block;
    text-decoration: underline;
}

.ytLink:after {
    @include linkArrow;
  }
    
  .ytLink:hover:after {
    @include linkArrowHover;
  }
