.bcr {
  position: relative;
  @include wrapper;
  margin-top: 12x;
  margin-bottom: 12px;
  @include text;
  @include link;
  @include linkItem;
  @include arrow;
  @include arrowUp;
}

.bcr:hover {
  @include linkHover;
}
