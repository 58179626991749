.cl:after {
    content: "";
    position: absolute;
    left: 2em;
    top: 0;
    height: 100%;
    border-left: solid 1px #cc3333;
}

.cl {  
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin: $lineHeight*2 0;
  padding: 0 0 $lineHeight 0;
  position: relative;
  clear: both;
  border-radius: 3px;

  h2 {
    margin: $lineHeight 0 0 2.2em;
    font-size: 1.1rem;
  }

  ul {
    background-image: url("data:image/gif;base64,R0lGODlhFgAVAHAAACH5BAEAAAEALAAAAAAWABUAgcvk9AAAAAAAAAAAAAIYjI+py+0Po5y02ouz3rz7D4YJQJbmiQIFADs");
    //background-image: url("data:image/gif;base64,R0lGODlhFgAsAJEAAP////n8/ePv9gAAACH5BAAHAP8ALAAAAAAWACwAAAInhI+py+0Po5y02ouz3rz7D4biSJbmiabqyrZuFsTyTNeBgOf6zgsFADs=");
    background-size: 100% 1.26em;
    list-style-type: circle;
    background-position: 0px 1px;
  }

  li {
    @include text;
    line-height: 1.4em;
    font-size: .9rem;
    color: #555555;
    position: relative;
    padding: 0 $gutter 0 4px;
    margin-left: 2.4em;
    top: 2px;
  }
/*
  li:before {
    content: "";
    position: absolute;
    left: .3em;
    top: -.1em;
    width: .85em;
    height: .85em;
    border: solid 1px #292;
    background-color: #fff;
    display: block;
  }

  li:after { 
    @include text;
    font-family: 'Open Sans', sans-serif;
    content: "x";
    position: absolute;
    top: -1px;
    left: 6px;
  }

  li.warning:before {
    content: "";
    position: absolute;
    left: .4em;
    top: .1em;
    width: 0px;
    height: 0px;
    border-top: 0;
    border-right: .4em solid transparent;
    border-bottom: .8em solid #cc0000;
    border-left: .4em solid transparent;
  }

  li.warning:after { 
    display: none;
  }
*/
  li a {
    @include asideLinkText;
  }

  li a:hover {
    @include linkHover;
  }

  li a:after {
    @include linkArrow;
  }

  li a:hover:after {
    @include linkArrowHover;
  }
}