.banner {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100px;
    text-align: center;
    padding: 10px;
    background: #000000;
    z-index: 999999;
  }
  
  .banner_wrapper {
    display: block;
    margin: 0 auto;
    position: relative;
    max-width: 375px;
    cursor: pointer;
  }
  
  .bannerBook {
    background: url(#{$imagePath}book.jpg) no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  .bannerBook {
    height: 80px;
    width: 65px;
    position: absolute;
    left: 0;
  }
  
  .bannerTile {
    display: block;
    padding-left: 65px;
    font-size: 12pt;
    color: #ffffff;
    width: 100%;
  }