.contentMenu {
  clear: both;

  ul {
    margin: 0;
  }

  li {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li:nth-child(1) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(2) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(3) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(4) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(5) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(6) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(7) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(8) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(9) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(10) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(11) {
    background-position: random(300)+px random(300)+px;
  }

  li:nth-child(12) {
    background-position: random(300)+px random(300)+px;
  }

  a {
    @include link;
  }

  a:hover {
    @include linkHover;
  }

  p {
    display: none;
    font-size: .85em;
    @include respond-to(large) {
      font-size: .9em;
      margin: 0;
      padding: 3px;
      color: #000000;
      display: block;
    }
  }

  p:after {
    @include linkArrow;
  }

  p:hover:after {
    @include linkArrowHover;
  }

  h3 {
    clear: inherit;
    margin: 0;
  }

  img {
    width: 160px;
    height: auto;
    float: left;
    margin-right: 10px;
   }
}

.ita {
  border: 1px dashed #00000012;
  background: url(#{$imagePath}ita.jpg);
  background-size: 10em;
  margin-bottom: $lineHeight*2;
  padding: $gutter $gutter*2 $gutter*.5;
  font-weight: 700;

  a {
    @include link;
    font-size: .9rem;
    margin-bottom: $lineHeight;

    @include arrow;
    @include arrowDown;
    }

  a:hover * {
    color: #000000;
  }
  
  p:after {
    border-color: transparent #530321;
  }
    
  p:hover:after {
    border-color: transparent #000000;
  }
  div {
    margin-top: 0;
    margin-bottom: $lineHeight *.5;
  }
}

.its {
  margin-bottom: $lineHeight;

  li {
    border-bottom: 1px dashed #cccccc;
    border: 1px dashed #00000012;
    background: url(#{$imagePath}its.jpg);
    margin-bottom: $lineHeight;
    padding: $gutter;
    background-size: 10em;
    float: left;
  }

  span {
    margin: $lineHeight*2 0 $lineHeight*.5 0;
    font-size: 1.4em;
    letter-spacing: -.02rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  li span {
    margin: $lineHeight*2 0 $lineHeight*.5 0;
    font-size: 1em;
    letter-spacing: -.02rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
/*
  li:nth-child(odd) {
    clear: left;
    margin-right: 6px;
    width: calc(50% - 6px);
    @include respond-to(small) {
      width: 100%;
    }
  }

  li:nth-child(even) {
    margin-left: 6px;
    width: calc(50% - 6px);
    @include respond-to(small) {
      width: 100%;
    }
  }
*/

  a {
    display: block;
  }

  a:hover * {
    color: #000000;
  }
    
  p:after {
    border-color: transparent #4c5f4a;
  }
    
  p:hover:after {
    border-color: transparent #000000;
  }
}

.ots {
  
  li {
    border: 1px dashed #00000012;
    background: url(#{$imagePath}ots.jpg);
    margin-bottom: $lineHeight;
    background-size: 10em;
    padding: $gutter;
  }

  span {
    margin: $lineHeight*2 0 $lineHeight*.5 0;
    font-size: 1.4rem;
    letter-spacing: -.02rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  li span {
    margin: $lineHeight*2 0 $lineHeight*.5 0;
    font-size: 1rem;
    letter-spacing: -.02rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  li p {
    margin: $lineHeight*2 0 $lineHeight*.5 0;
    font-size: .9rem;
    letter-spacing: -.02rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  a {
    display: block;
  }

  a:hover * {
    color: #000000;
  }
    
  p:after {
    border-color: transparent #004170;
  }
    
  p:hover:after {
    border-color: transparent #000000;
  }
}

aside {
  p {
    display: none !important;
  }

  @include respond-to(large) {
    img {
      width: 120px;
    }
  }
}