$textColour: #333333;

$signatureColourTint: rgba(31, 91, 84, 0.8);
$signatureColourBright: rgb(246,240,178);
$signatureColourBase: rgb(31, 91, 84);
$signatureColourDark: rgb(15, 45, 40);

$primaryCallToActionTint: rgba(31, 91, 84, 1);
$primaryCallToActionBright: #ac0086;
//$primaryCallToActionBright: rgb(85, 4, 51);
$primaryCallToActionBase: rgb(90, 122, 0);
$primaryCallToActionDark: rgb(31, 91, 84);


$callToActionTint: #cccccc;
$callToActionBright: #555555;
$callToActionBase: #052378;
$callToActionDark: #000000;

$StructuralTint: rgba(31, 91, 84, 0.8);
$StructuralBright: rgb(31, 91, 84);
$StructuralBase: rgb(31, 91, 84);
$StructuralDark: rgb(31, 91, 84);