ul.steps {
  margin-bottom: $lineHeight;
  @include text;
  clear: both;
  //overflow: hidden;

li {
  //overflow: hidden;
  margin-bottom: $lineHeight * .5;
  //padding-bottom: 20px;
  //border-bottom: 1px solid #559955;
  min-height: 60px;
  @include respond-to(large) {
    width: 50%;
    float: left;
    border-bottom: 0;
    margin-bottom: $lineHeight * 2;
  }
}

li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

li:nth-child(odd) {
  clear: left;
  h3,
  p {
    @include respond-to(large) {
      padding-right: 20px;
    }
  }
}

h3 {
  margin-bottom: 3px !important;
  @include respond-to(large) {
    width: 50%;
    float: right;
    padding: 0  0 0 20px;
  }
}

p {
  @include respond-to(large) {
    width: 50%;
    float: right;
    clear: both;
    padding: 0 20px;
  }
}

img {
  width: 100%;
  @include respond-to(large) {
    width: 50%;
  }
}

}