.amazon {
  text-align: center;
  margin-bottom: $lineHeight;
  width: 100%;
  cursor: pointer;

  h2{
    color: #4f2378;
  }
}

.amazon iframe {
	border: solid 1px grey;
  margin: 0 auto;
}


.frm div {
  display: none;
}
/*
[data-ab=ab1] .frm {
  line-height: 1.5rem!important;
  padding: 6px 0;
}

[data-cc=GB] .frm span {
  &:before {
    content: "£";
  }
}

[data-cc=CA] .frm span {
  &:before {
    content: "CA$";
  }
}

[data-cc=US] .frm span {
  &:before {
    content: "$";
  }
}*/