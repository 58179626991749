#twitter-widget-1 {
	width: 100% !important;
	text-align: center;
	padding: 0 $gutter;
	@include respond-to(desktop) {
		padding: 0;
	}
}

.FacebookFeed {
	padding: 0 $gutter;
	@include respond-to(desktop) {
		padding: 0;
	}
}
