@mixin arrow {
  display: flex;
  align-items: center;

  &:after,
  &:before {
    display: block;
    position: relative;
    content: "";
  }

  &:before {
    order: -2;
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-left: 2px solid;
  }

  &:after {
    order: -1;
    position: relative;
    left: -5px;
    width: 4px;
    height: 10px;
    border-left: 2px solid;
  }
}

@mixin arrowUp {
    &:before {
        top: -2px;
        transform: rotate(45deg);
    }
    &:after {
        border-bottom: 2px solid;
    }
  }

  @mixin arrowDown {
    &:before {
        top: 2px;
        transform: rotate(225deg);
    }
    &:after {
        border-top: 2px solid;
    }
  }