
//article
.moveElement {
  font-size: 9pt;
  position: absolute;
  top: 0;
  right: 15px;
  text-align: right;
  text-align: center;
  width: 90px;
  margin: 0 3px;
}

//formElement
.formSection .moveElement,
.formSection .moveElement,
.formSection .moveElement {
  border: solid 1px #555555;
  border-bottom: 0;
  border-top: 0;
  background: #00eeda;
  height: 100%;
  width: 90px;
  outline: dotted 1px #cccccc;
}

.articleSection .formSection:nth-child(3) .moveElement {
  border-radius: 5px 5px 0 0;
  border: solid 1px #555555;
  border-bottom: 0;
  background: #00eeda;
}

.articleSection > .formSection:last-child .moveElement {
  border-radius: 0 0 5px 5px;
  border: solid 1px #555555;
  border-top: 0;
  background: #00eeda;
}

//element
.formElement-list .moveElement,
.formElement-linksList .moveElement,
.formElement-paras .moveElement,
.formElement-links .moveElement,
.formElement-featuresList .moveElement {
  right: -92px;
  width: 84px;
  border: solid 1px #555555;
  border-bottom: 0;
  border-top: 0;
  background: #eeee00;
  height: 100%;
  z-index: 1;
}

.formElement-list .formElement_control:nth-child(2) .moveElement,
.formElement-linksList .formElement_control:nth-child(1) .moveElement,
.formElement-paras .formElement_control:nth-child(3) .moveElement,
.formElement-links .formElement_control:nth-child(2) .moveElement,
.formElement-featuresList .formElement_control:first-child .moveElement {
  border-radius: 5px 5px 0 0;
  border: solid 1px #555555;
  border-bottom: 0;
  background: #eeee00;
}

.formElement-list .formElement_control:last-Child .moveElement,
.formElement-linksList .formElement_control:last-child .moveElement,
.formElement-paras .formElement_control:last-Child .moveElement,
.formElement-links .formElement_control:last-Child .moveElement,
.formElement-featuresList .formElement_control:last-child .moveElement {
  border-radius: 0 0 5px 5px;
  border: solid 1px #555555;
  border-top: 0;
  background: #eeee00;
}

.formElement_move .formElement_move_up,
.moveElement .moveElement_up,
.formElement_move .formElement_move_down,
.moveElement .moveElement_down {
  width: 34px;
  height: 18px;
  border: solid 1px #999999;
  padding: 3px;
  margin: 3px;
  background-color: #ffffff;
  display: inline-block;
  text-align: center;
  font-size: 8pt;
}