.dyk {  
  border: solid 1px #b49caa;
  margin : 0 0 $lineHeight 0 !important;
  padding: $gutter;
  position: relative;
  background-color: #b49caa;
  background: url(#{$imagePath}dyk.jpg);
  border-radius: $borderRadius;
  overflow: hidden;
  clear: both;

  h2, h3, p {
    background-color: rgba(255,255,255,0.8);
    padding: $lineHeight*.5 $gutter;
    margin: 0;
    width: 100%;
  }

  img {
    display: block;
    margin: $lineHeight*.5  $gutter 0 $gutter;
    float: right;
    width: 160px;
  }
}