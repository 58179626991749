.top10 {
	border-bottom: dashed 1px #215b54;
	margin-bottom: $lineHeight;
	padding-bottom: 12px;
	clear: left;
	overflow: hidden;
}

.top10 h2 {
	line-height: 20pt;
	font-size: 24px;
	@include respond-to(tablet) {
		margin: 0 0 16px 0;
	}
}

.top10 h2 span {
	text-align: center;
	padding-top:5px;
	font-size: 18pt;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 6px;
	@include respond-to(tablet) {
		width: 60px;
		height: 60px;
		padding-left:22px;
		padding-top:12px;
		font-size: 22pt;
	}
}

.top10 h2 span {
	border: solid 2px #ffdd00;
}

.top10 p a {
	color: #729b00!important;
}

@mixin awardRibbonCorner {
	display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: -35px;
    top: 42px;
    content: "";
    border-style: solid;
    border-width: 0px 14px 20px 20px;
	z-index: 1;
}

@mixin awardRibbon {
	display: block;
    height: 30px;
	color: #ffffff;
	position: absolute;
    left: -15px;
    top: 12px;
	text-align: center;
	line-height: 30px;
	box-shadow: #5e5e5e8c 2px 2px 2px 1px;
}

.award {
	position: relative;
}

.award:before {
	@include awardRibbon;
	width: 140px;
}

.award:after {
	@include awardRibbonCorner;
  }

.award-fun:before {
	content: "Most fun";
	background: #cc0000;
}

.award-fun:after {
	border-color: transparent #cc0000 transparent transparent;
}

.award-vfm:before {
	content: "Best value for money";
	background: #045e34;
}

.award-vfm:after {
	border-color: transparent #045e34 transparent transparent;
}

.award-mes:before {
	content: "Least mess";
	background: #a78000;
}

.award-mes:after {
	border-color: transparent #a78000 transparent transparent;
}

/*
.awardSum {
  @include text;
  margin-top: 24px;
  border: solid 2px #82b000;
  position: relative;
  padding-bottom: 16px;

  &.award-1:before {
	content: "Best value for money";
	width: 200px;
	@include awardRibbonCorner;
  }

  &.award-2:before {
	content: "Best all rounder";
	width: 160px;
	@include awardRibbonCorner;
  }

  &.award-3:before {
	content: "Durable";
	width: 110px;
	@include awardRibbonCorner;
  }

  &.award-5:before {
	content: "Least mess";
	width: 140px;
	@include awardRibbonCorner;
  }

  &.award:after {
	display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: -15px;
    top: 42px;
    content: "";
    border-bottom: 23px solid transparent;
	z-index: 1;
	border-right: 13px solid#990000;
  }
}*/