$borderRadius: 6px;
$lineHeight: .93em;
$textColour: #333333;
$letterSpacingText: .05em;
$wordSpacingText: -.1em;
$lineHeightText: 1.4;
/* website 
body {
  font-size: 16px;
}
$gutter: 10px;

/* book */
body {
  font-size: 16px;
}

body.book,
.output {
  font-size: 10.8px;
  font-size: 11.3px;
}

$gutter: 8px;
@include respond-to(small) {
  $gutter: 16px;
}