body, 
html {
  height: 100%;
} 

@mixin clearFix {
  content: "";
  display: table;
  clear: both;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none; 
}