@mixin linkArrow {
  content: "";
  border-color: transparent $callToActionBase;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: inline-block;
  height: 0;
  width: 0;
  margin: 0 0 0 6px;
  position: relative;
  top: 1px;
}

@mixin linkArrowHover {
  border-color: transparent $callToActionDark;
}