p {
  @include text;
  margin: 0 0 $lineHeight 0;

  a {
    @include link;
  }
  
  a:hover {
    @include linkHover;
  }
}

img {
  margin: 0;
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: $lineHeight;
}

.insert {
  @include text;
  font-size: 1rem;
  line-height: 1.3;
  border: 1px solid #555555;
  border-left: 0;
  border-right: 0;
  text-align: center;
  margin: 12px auto;
  padding: 6px 3px;
  width: 80%;
  display: block;
  text-align: center;
  @include respond-to(large) {
    float: left;
    width: 300px;
    margin: 5px $gutter 0 0;
    padding: 6px 12px;
  }
}

/*
.youtube {
  width: 100%;
  height: auto;
}
*/
figure {
  margin-bottom: $lineHeight * .5;

  figcaption {
    @include subText;
    border-bottom: 1px solid #999999;
    padding: 9px $gutter;
    margin: 0 0 $lineHeight*2 0;
  }
}
/*
.pageContent.menu figure {
  float: right!important;
  margin-left: 1em!important;
  width: 280px!important;
}
*/