// RESPONSIVE DESIGH BREAK POINTS
@mixin respond-to($media) {
  @if $media == large {
    @media only screen and (min-width: 1200px) { @content; }
  }
  @else if $media == small {
    @media only screen and (min-width: 768px) { @content; }
  }
  @else if $media == verySmall {
    @media only screen and (min-width: 414px) { @content; }
  }
}