.googleAdWrapper {
  text-align: center;
  width: 100%;
  margin: 12px 0;
  clear: both;
}

.googleAd {
  border: solid 1px #cccccc;
  display: inline-block;
  width: 100%;
  margin-bottom: 9px;
}

.googleAd_header {
  @include text;
  font-size: 8pt;
  color: #cccccc;
}
