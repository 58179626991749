//$fontFamily: Arial, Helvetica, sans-serif;
//$fontFamily: 'Segoe UI';
$fontFamily: 'Open Sans', sans-serif;
$titleFontFamily: Arial, Helvetica, sans-serif;

@mixin text {
  font-size: 1rem;
  letter-spacing: $letterSpacingText;
  word-spacing: $wordSpacingText;
  color: $textColour;
  line-height: $lineHeightText;
  text-align: left;
}

@mixin textLarge {
  font-size: 1rem;
  letter-spacing: $letterSpacingText;
  word-spacing: $wordSpacingText;
  color: $textColour;
  line-height: $lineHeightText;
}

@mixin subText {
  font-size: .85em;
  letter-spacing: $letterSpacingText;
  word-spacing: $wordSpacingText;
  color: $textColour;
  line-height: $lineHeightText;
}

@mixin asideLinkTitle {
  font-size: 13pt;
  line-height: 22px;
}

@mixin asideLinkText {
  @include text;
  text-decoration: none;
}

@mixin link {
  text-decoration: none;
  color: $callToActionBase;
}

@mixin linkHover {
  color: $callToActionDark;
}

@mixin linkItem {
  line-height: 36px;
}

@mixin facebookTitle {
  font-size: 1.1em;
  line-height: 1.1em;
}

@mixin facebookText {
  font-size: .9em;
  line-height: 1.1em;
  color: #555555;
}

@mixin facebookLink { 
  text-decoration: none;
  color: #385898;
  font-weight: 700;
}

.link,
.link:hover {
  color: $primaryCallToActionBright;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

body {
  font-family: $fontFamily;
}

h1, h2, h3 {
  color: $textColour;
  font-weight: 700;
  break-inside: avoid;
  letter-spacing: $letterSpacingText;
  word-spacing: $wordSpacingText;
  line-height: $lineHeightText;
}

h1 {
  @include wrapper;
  font-size: 1.6em;
  line-height: 1.4;
  margin-bottom: 24px;
  margin-top: 12px;
}

@mixin h2 {
  margin: $lineHeight*2 0 $lineHeight*.5 0;
  clear: both;
  font-size: 1.2em;
  letter-spacing: -.02rem;
  line-height: 1.5rem;
}

h2 {
  @include h2;
}


@mixin h3 {
  margin: 0 0 $lineHeight 0;
  font-size: 1em;
}

h3 {
  @include h3;
}