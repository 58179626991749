$myColor1: red;
$myColor2: green;

h1 {
  color: $myColor1;
}

h1 {
  color: $myColor2;
}


@import "css/base";