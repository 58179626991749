.fb {
  display: flex;
  flex-direction: column;
  margin: $lineHeight*2 0;

 img {
    border-radius: $borderRadius;
    margin-left: 2em;
    width: calc(100% - 2em);
    order: 2
  }

  figcaption {
    order: 1;
    width: calc(100% - 2em);
    border: 0;
    background: #f2f3f5;
    border-radius: $borderRadius;
    color: #1c1e21;
    display: inline-block !important;
    @include facebookText;
    padding: $gutter;
    margin: 0 0 0 2em;
    position: relative;
    line-height: 1.3em;
  }

  figcaption:before {
    font-family: Arial, Helvetica, sans-serif;
    content: "f";
    width: .7em;
    height: .7em;
    display: block;
    background-color: #4267b2;
    border-radius: 1em;
    font-size: 3em;
    color: white;
    position: absolute;
    left: -.8em;
    top: 4px;
    line-height: 1em;
    text-align: center;
    overflow: hidden;
  }

  div {
    @include facebookTitle;
    margin-bottom: 6px;
    span,
    a:link,
    a:hover,
    a:visited {
      @include facebookLink; 
    }
    
  }
}

.joinFacebook {
  background-color: #ECEFF5;
  padding: $gutter;
  margin: 0 20px $lineHeight 20px;
  position: relative;
  background-size: 150px 87px;
  border: solid 1px #b2cbfe;
  border-radius: $gutter;
}

a {
  text-decoration: none;
}

.joinFacebook:before {
  @include text;
  content: "f";
  position: absolute;
  left: $gutter;
  top: $gutter;
  width: 30px;
  height: 30px;
  display: block;
  background-color: #4267b2;
  border-radius: 15pt;
  font-size: 36pt;
  color: white;
  line-height: 45px;
  text-align: center;
  overflow: hidden;
}

.joinFacebook h2 {
  @include facebookTitle;
  margin: 0 0 $lineHeight 36px;
  font-weight: bold;
  padding-top: 7px;
}

.joinFacebook div {
  @include facebookText;
  margin: 20px 0 $lineHeight;
  padding: 5px;
  background: #cccccc;
  border: solid 1px #999999;
  width: 100px;
}

.joinFacebook div:hover {
  background: #dddddd;
}

.joinFacebook p {
   @include facebookText;
}