@media only screen and (min-width: 640px) {
  .warningMessage {
    font-size: 8pt;
    margin: 0 6px 6px 0;
    display: inline-block;
    color:#990000;
    cursor: pointer;
    padding: 1px;
  }
} 
  
  
.formElement_control .counter {
  position: absolute;
  right: 12px;
  top: -12px;
  font-size: 6pt;
  color: #555555;
}
  
@media only screen and (min-width: 640px) {
  .formElement_control .counter {
    top: 3px;
  }
}
  
@media only screen and (min-width: 640px) {
  .formElement.validation .formElement_control_input {
   padding-right: 40px;
  }
}
  
.warning .counter {
  color: #990000;
}
  
.warning .formElement_control_input {
  border: solid 1px #990000;
  background-color: #fff0f0!important; 
}