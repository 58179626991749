/* NIGHT WORKS */
body.night {
  background-color: #000000 !important;
}
.night .formElement_control_input,
.night .formWrapper,
.night .button {
  background-color: #000000 !important;
  border: 0;
}

.night .categorySection {
  background-color: #222222;
  border: 0;
}

.night .articleSection {
  background-color: #444444;
  border: 0;
}

.night .formElement_control_input {
  border: 0;
  color: #999999;
}

.night .nav div,
.night .button,
.night  .menu div,
.night  .formElement_label,
.night  legend,
.night .navigation  {
  color: #cccccc;
}

.night .counter {
  font-size: 6pt;
  color: #555555;
}

.night .catSection {
  background-color: #444444;
}

.night .moveElement {
  color: #ffffff;
}