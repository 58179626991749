.nav {
    padding: 10px 10px 12px 10px;
    position: fixed;
    top: 0;
    background-color: #ffffff;
    z-index: 100;
    width: 100%;
  }
  
  .nav div {
    float: left;
    margin-right: 10px;
    position: relative;
    padding: 3px;
    cursor: pointer;
    font-size: 9pt;
  }
  
  .nav .selected {
    color: #0066cc;
  }
  
  .nav .formElement_control_input {
    float: right;
  }

  .navigation {
    width: 30%;
    position: fixed;
    font-size: 9pt;
    height: 94%;
    overflow-y: scroll;
    padding-top: 50px;
  }
  
  .navigation .elements {
    padding: 3px;
    border-bottom: 1px solid #cccccc;
    cursor: pointer;
  }

  .productNav .elements{
    //height: 56px;
    font-size: 16px;
    padding: 12px;
    position: relative;
  }

  .navigation .img {
    position: absolute;
    right: 0;
    margin-top: -56px;
  }

  .navigation .menu {
    margin: 0;
    padding: 0;
    display: none;
  }
  
  .navigation .menu div {
    margin: 0 6px 3px 0;
    padding: 0;
    font-size: 8pt;
    color: #606087;
  }
  
  .navSection {
    background: #dddddd;
  }