.asideTile{
  text-align: center;
  width: 100%;
  margin: 25px 0 25px 0;
  clear: both;
  cursor: pointer;
  border: solid 1px #cccccc;
  padding: 16px 8px;
}

.asideTile_title {
  @include text;
  font-size: 14pt;
  color: #4f2378;
  padding-bottom: 24px;
  font-size: 1.4rem;
}

