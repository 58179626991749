.quickLinks {
  margin: $lineHeight 0;
}

.quickLinks h2 {
  @include text;
  margin-top: 0;
}

.quickLinks a {
  @include link;
  line-height: 36px;
}
  
.quickLinks a:hover {
  @include linkHover;
}
  
.quickLinks a:after {
  @include linkArrow;
}
  
.quickLinks a:hover:after {
  @include linkArrowHover;
}