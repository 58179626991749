.header {
  text-align: center;
}

.header_logo {
  display: inline-block;
  height:30px;
  width: 300px;
  background: url(#{$imagePath}logo-m.gif) no-repeat;
  margin: 12px 0 2px;
  @include respond-to(small) {
    background: url(#{$imagePath}logo.gif) no-repeat;
    height: 50px;
    width: 200px;
    position: relative;
    margin: 2em;
  }
}

.headerWrapper {
  background-color: $signatureColourBase;
  background: url(#{$imagePath}wp1l.jpg);
  background-size: 150px;
  max-width: 100%;
  width: 100%;
  z-index: 100;
  box-shadow: 0 1px 4px 2px rgba(174, 156, 136, 0.4);
  border-bottom: #bfb8b2;
  @include respond-to(small) {
  //  position: relative;
  }
}

.header_social {
  background: #212f19;
  position: absolute;
  top: 100px;
  width: 100%;
  box-shadow: 0 0 4px 3px rgba(33, 47, 25, 0.53);
  border-bottom: #bfb8b2;
  height: 26px;
  padding-top: 3px;
  @include respond-to(small) {
    background: transparent;
    right: 0;
    width: auto;
    box-shadow: none;
  }

  .facebookGroupLogo-24x24,
  .facebookPageLogo-24x24,
  .twitterLogo-24x24,
  .youtubeLogo-24x24 {
    color: #ffffff;
    text-decoration: none;
    font-size: 10pt;
    font-family: $titleFontFamily;
    padding: 0 3px 0 28px;
    line-height: 26px;
    span {
      display: none;
    }
    @include respond-to(small) {
      color: #000000;
      font-size: 12pt;
    }
  }
}

.facebookGroupLogo-24x24 {
  background: url(#{$imagePath}facebook-logo-24x24.gif) 3px 0 no-repeat;
  height:18px;
  display: inline-block;
  line-height: 20px!important;
}

.twitterLogo-24x24 {
  background: url(#{$imagePath}twitter-logo-24x24.gif) 3px 0 no-repeat;
  height:18px;
  display: inline-block;
  line-height: 20px!important;
}

.youtubeLogo-24x24 {
  background: url(#{$imagePath}youtube-logo-24x24.gif) 3px 0 no-repeat;
  height:18px;
  display: inline-block;
  line-height: 20px!important;
}