.list {
  margin-bottom: $lineHeight;

  h2 {
    font-size: 1em;
  }

  h3 {
    font-size: .9em;
  }

  ul {
    margin-left: 2em;
    list-style-type: square;
  }
    
  ul li {
    @include text;
    margin-bottom: $lineHeight * .25;
  }

}
  