body {
  height: 100%;

  * {
    box-sizing: border-box;
    font-family: Arial,Helvetica Neue, Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 400px auto;
}

.aside {
  overflow-y: scroll;
  height: 100vh;

  li {
    border-bottom: 1px solid #cccccc;
    clear: both;
    padding: 8px;
    display: grid;
    grid-template-columns: 48px auto  38px 38px;
    gap: 3px;
    min-height: 48px;
    cursor: pointer;

     img {
      width: 60px;
      height: auto;
      float: right;
     }
  }
}

.page {
  .formSection {
    clear: both;
    display: grid;
    grid-template-columns: auto 38px 38px;
    gap: 3px;
    min-height: 48px;
    cursor: pointer;
  }
}

/*
.main {
  
}

.editSection {
  width: 70%;
  margin-left: 30%;
  padding-top: 70px;
}

.categorySection {
  background: #fcfcfc;
  border: solid 1px #fefefe;
  padding: 0;
  margin: 0 0 12px; 
  position: relative;
}

.articleSection {
  //border: solid 1px #d5d5d5;
  //padding: 6px 6px;
  margin: 24px 0 0 0;
  position: relative;
  overflow: hidden;
  min-height: 50px;
  clear: left;
}

.product {
  padding: 8px;
  display: grid;
  grid-template-columns: 48px auto  38px 38px;
  gap: 3px;
  min-height: 48px;
}*/