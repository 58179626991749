.contentWrapper {
  
  @include wrapper;

    @include respond-to(small) {
    overflow: hidden;
    width: 60%;
    width: calc(100% - 400px);
    margin-left: 0;
  }

  @include respond-to(large) {
    width: 773px;
    position: relative;
    left: calc(50% - 600px);

  }
}

.contentMenu {
  @include respond-to(small) {
    flex-grow: 1;
    order: 1;
  }
}

.content {
  //padding: 0.93em $gutter*2 16px;
 // max-width: 1200px !important;
 // margin: 0 auto;

  @include respond-to(small) {
    //padding: 0.93em 392px 0 $gutter;
  }
}

aside {
 
  @include respond-to(small) {
    position: absolute;
    top: 325px;
    right: $gutter;
    width: 360px;
  }

  @include respond-to(large) {
    left: calc(50% + 214px);
    right: initial;
  }
}