.bstSum {
	&:first-of-type {
		margin-top: 32px;
	}
	margin-bottom: 24px;
	grid-template-areas:
		"hdr hdr"
		"img img"
		"pdt pdt"
		"rat svdt"
		"mor mor";
	grid-template-rows: 1fr auto auto auto 30px;
	grid-template-columns: 1fr 1fr;
	border: solid 2px #82b000;
	gap: 10px;
	column-gap: 20px;
	padding: 24px 10px 10px 10px;

	@include respond-to(verySmall) {
		grid-template-areas:
			"img rat"
			"img pdt"
			"hdr hdr"
			"svdt svdt"
			"mor mor";
		grid-template-rows: auto 1fr auto auto 30px;
		grid-template-columns: 1fr 150px;

	}

	@include respond-to(large) {
		grid-template-areas:
			"img hdr rat"
			"img svdt rat"
			"pdt mor rat";
		grid-template-rows: auto 1fr auto;
		grid-template-columns: 1fr 1fr 150px;

	}
}

div:nth-last-of-type(.bstSum) {
	margin-bottom: 24px;
}

.bst {
	&:first-of-type {
		margin-top: 32px;
	}
	margin-bottom: 32px;
    border-bottom: solid 1px #cccccc;
	grid-template-areas:
		"hdr"
		"svdt"
		"img"
		"pdt"
		"vdt"
		"ovdt"
		"rat"
		"key";
	grid-template-rows: auto 1fr auto auto auto auto auto auto;
	grid-template-columns: 1fr;
	row-gap: 10px;
	column-gap: 20px;
	padding-bottom: 24px;

	@include respond-to(verySmall) {
		grid-template-areas:
		"hdr hdr hdr hdr"
		"img img img rat"
		"img img img pdt"
		"vdt vdt vdt vdt"
		"ovdt ovdt ovdt ovdt"
		"svdt svdt key key";
		grid-template-rows: auto 1fr auto auto auto auto;
		grid-template-columns: 150px 1fr 1fr 150px;
	}
}

.bst:first-of-type {
    margin-top: 32px;
}

.bstSum:before,
.bst:before {
	top: -16px;
}

.bstSum:after,
.bst:after {
	top: 12px;
}

.bst:before {
	top: -40px;
}

.bst:after {
	top: -12px;
}

.bst,
.bstSum {
	display: grid;
	width: 100%;

	.btn {
		grid-area: pdt;

		> div {
			cursor: pointer;
			@include button;
			line-height: 1.4;
			text-align: center;
			margin: 0 auto 8px;
			padding: 6px 0;
		}
	}

	h2 {
		grid-area: hdr;
		margin: 0 0 3px 0;
		font-size: 16pt;

		span {
			border: solid 2px #ffdd00;
			text-align: center;
			padding-top: 6px;
			font-size: 18pt;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: inline-block;
			margin-right: 6px;
		}
	}

	h3 {
		margin: 0;
	}

	.key {
		grid-area: key;
		@include text;
		ul {
			list-style-type: square;
			margin-left: 14px;
		}
	}

	.mor {
		grid-area: mor;
		display: none;
		height: 36px;
		@include link;
		@include arrow;
		@include arrowDown;
		@include respond-to(verySmall) {
			display: flex;
		}
	}

	.vdt {
		grid-area: vdt;
		@include text;
	}

	.svdt {
		grid-area: svdt;
		@include textLarge;
	}

	.ovdt {
		grid-area: ovdt;
		@include text;
	}

	.img {
		grid-area: img;
		cursor: pointer;
		//text-align: center;
		//flex-direction: column;
		//justify-content: center;
		//min-height: 160px;
	}

	.rat {
		grid-area: rat;
		padding: 0;
		font-weight: 400;
		text-align: center;
		font-size: 1rem;

		.happy,
		.happy div,
		.dolar,
		.dolar div,
		.mess,
		.mess div,
		.noise,
		.noise div {
			background-size: 20px;
			width: 100px;
			text-align: left;
		}

		div {
			display: block;
			margin: 0 0 12px;
		}

		div div {
			height: 24px;
		}

		.happy {
			background: url(#{$imagePath}happy-bg.jpg) repeat;
			width: 120px;
			margin: 0 auto 12px;
		}

		.happy div {
			background: url(#{$imagePath}happy-icon.jpg) repeat;
		}

		.dolar {
			background: url(#{$imagePath}dolar-bg.jpg) repeat;
			width: 120px;
			margin: 0 auto 12px;
		}

		.dolar div {
			background: url(#{$imagePath}dolar-icon.jpg) repeat;
		}

		.mess {
			background: url(#{$imagePath}mess-bg.jpg) repeat;
			width: 120px;
			margin: 0 auto 12px;
		}

		.mess div {
			background: url(#{$imagePath}mess-icon.jpg) repeat;
		}

		.noise {
			background: url(#{$imagePath}noise-bg.jpg) repeat;
			width: 120px;
			margin: 0 auto 12px;
		}

		.noise div {
			background: url(#{$imagePath}noise-icon.jpg) repeat;
		}

		.ratingPoints {
			//font-size: 10pt;
			font-weight: 700;
		}

	}
}

[data-cd="co.uk"] {
	border: 0;
	.ca_title, 
	.btn,
	.ca,
	.googleAdWrapper {
		display: none;
	}
}