.page {
background-color: #eeeeee;
padding: 10px;
}

.section {
  width: 100%;

  .sectionTitle {
    margin-left: 200px;
  }

  
  .section_item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.formEl {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;
  gap: 6px;

  .formEl_lable {
    width: 200px;
    text-align: right;
    font-size: 9pt;
    font-weight: 600;

  } 
  
  &.formEl-textBox {
    input[type=text]{
      width: 100%;
      padding: 3px;
      border: 0;
    }
  }

  &.formEl-textArea {
    textarea {
      width: 100%;
      border: 0;
      padding: 3px;
    }
  }

  &.formEl-radios {
    div {
      width: 100%;
    }
  }
}

.dragIcon {
  background-image: radial-gradient(#444444 2px, transparent 0);
  background-size: 9px 11px;
  border: solid 1px #cccccc;
  outline: solid 2px #999999;
  width: 30px;
  height: 36px;
  min-height: 36px;
  background-color: #cccccc;
  background-position: 1px 1px;

}

.dragIcon:after {
  content: "";
  display: block;
  background-image: radial-gradient(#cccccc 2px, transparent 0);
  background-size: 9px 11px;
  width: 30px;
  height: 100%;
  background-position: 2px 2px;
}

button {
  width: 36px;
  height: 18px;
}





.legend {
  font-size: 10pt !important;
  font-family: "Open Sans", sans-serif;
  color: #333333;
  margin: 0;
  letter-spacing: -0.0425em;
  font-weight: 400;
  width: 100%;
  margin: 0 0 6px 10px; 
}
  
@media only screen and (min-width: 640px) {
  .legend {
    margin-left: 400px;
    width: calc(100% - 400px);
    padding-left: 10px;
  }
}
  
.legend-level1 {
  font-size: 14pt !important;
}
  
.legend-level2 {
  font-size: 13pt !important;
}
  
.legend-level3 {
  font-size: 12pt !important;
}
  
.formWrapper {
  border: solid 1px #cccccc;
  background: #efefef;
  margin: 0 10px 6px 10px;
  padding: 6px 10px 0 10px;
}


.formElement {
  font-size: 9pt;
  font-family: "Open Sans", sans-serif !important;
  color: #333333;
  letter-spacing: 0.03em;
  font-weight: normal;
  display:flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 3px 0;
}

.formSection {
  position: relative;
}

.meta {
  background-color: #eeeeee;
}

.formElement_label {
  display: flex;
  gap: 5px;
  font-size: 9pt !important;
  font-family: "Open Sans", sans-serif;
  color: #333333;
  letter-spacing: -0.0225em;
  float: left;
  width: 100%;
  margin: 4px 0;
  padding: 0 10px;
  text-align: left;
}
  
.formElement_control {
  position: relative;
  width: 100%;
  padding: 0 10px;
  text-align: left;
  clear: both;
  @media only screen and (min-width: 640px) {
     //left: 400px;
     //width: calc(100% - 510px);
  }
}
  
.formElement_control_label {
  position: absolute;
  //left: -200px;
  //width: 200px;
  text-align: right; 
}

//.formElement-textBox .formElement_control_input,
.formElement_control-textBox input {
  font-size: 9pt;
  font-family: "Open Sans", sans-serif !important;
  color: #333333;
  line-height: 12px;
  letter-spacing: 0.03em;
  font-weight: normal;
  height: 24px;
  padding: 3px;
  width: 100%;
  background: #ffffff;
  border: solid 1px #cccccc;
  width: 100%;
}

.articleSection [data-showhide-toggle] {
  font-size: 12pt;
  font-weight: 700;
}

.formElement_control-dropDown select {
  font-size: 9pt;
  font-family: "Open Sans", sans-serif !important;
  color: #333333;
  line-height: 12px;
  letter-spacing: 0.03em;
  font-weight: normal;
  height: 24px !important;
  padding-top: 1px;
  padding-left: 1px;
  cursor: pointer;
  width: 100%; 
  background: #ffffff;
  border: solid 1px #cccccc;
}

select.formElement_control_input-awards {
  width: 160px; 
}

.formElement_control-checkBox input {
  border: solid 1px #cccccc;
  margin-top: 3px;
}

.formElement_control-textArea textarea {
  font-size: 9pt;
  font-family: "Open Sans", sans-serif !important;
  color: #333333;
  line-height: 12px;
  letter-spacing: 0.03em;
  font-weight: normal;
  height: 23px;
  padding: 3px;
  width: 100%;
  background: #ffffff;
  border: solid 1px #cccccc;
}

.formElement_control-linked {
  display: none;
}

.link + .formElement_control-linked {
  display: block;
}

.formElement_control_image {
  margin: 6px 0 0 410px;
  width: calc(100% - 531px);
  display: block;
}

.button .formElement_control_input {
  float: left;
  margin-right: 3px;
}

.button {
  font-family: "Open Sans", sans-serif !important;
  font-size: 9pt;
  color: #333333;
  line-height: 12px;
  letter-spacing: 0.03em;
  font-weight: normal;
  text-decoration: none;
  color: #ffffff;
  background-color: #292cd8;
  border: solid 1px #151452;
  cursor: pointer !important;
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-right: 3px;
}

.formElement_type {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  font-size: 9pt;
  font-family: "Open Sans", sans-serif !important;
  color: #333333;
  line-height: 12px;
  letter-spacing: 0.03em;
  font-weight: normal;
  height: 24px !important;
  padding-top: 1px;
  padding-left: 1px;
  cursor: pointer;
  background: #ffffff;
  border: solid 1px #cccccc;
}

.formElement-pagebreak {
  position: absolute;
  right: 0;
  top: 0;
}

